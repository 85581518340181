.upload__btn {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: start;
  max-width: 330px;
}

.upload__btn__subtitle {
  color: #797979;
  font-size: 14px;
}
/*---------------------------------------------------------------------------------------------*/
/*                                  MEDIA MIN_WIDTH                                            */
/*---------------------------------------------------------------------------------------------*/
