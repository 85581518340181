.form {
  margin-top: 66px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

/*---------------------------------------------------------------------------------------------*/
/*                                  MEDIA MIN_WIDTH                                            */
/*---------------------------------------------------------------------------------------------*/

@media (min-width: 620px) {
  .form {
    margin: 82px auto 0 auto;
    width: 580px;
    gap: 50px;

  }
}

@media (min-width: 1000px) {
  .form {
    width: 960px;
  }

}
