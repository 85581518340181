.main {
  opacity: 0;

  animation: fadeIn 1s ease-in-out forwards;

  background-image: url("../../../../public/backgroind image main.png");
  background-size: auto 100%; /* 80% высоты экрана */
  background-position: top right;
  background-repeat: no-repeat;

  padding: 27px 15px 0 15px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.main-container {
  box-sizing: border-box;
  max-width: 1113px;
  color: #4B4A4D;
  text-align: center;

  margin: 50px auto 343px auto;
}

.main-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

}

.main-subtitle {
  display: inline-block;
  max-width: 330px;

  color: #CDCDCD;

  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 2s 3s ease forwards;
  font-size: 16px;
}

/*---------------------------------------------------------------------------------------------*/
/*                                  ANIMATION                                                  */
/*---------------------------------------------------------------------------------------------*/
.portal-up-line {
  opacity: 0;
  display: flex;
  justify-content: center;
  position: relative;
  animation: fadeIn 2s 2s ease-in-out forwards;

}
.portal-up-line svg{
  position: absolute;
  top:-433px;
}

.portal-up-beam {
  width: 130px;
  height: 100px;
  position: absolute;
  left: 50%;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
}

.portal-up-beam span {
  position: absolute;
  animation: animate-portal-up-beam 2s linear infinite;
}

.portal-up-beam span:before {
  opacity: 0;

  content: '';
  position: absolute;
  transform: translateY(-50%);
  width: 300px;
  height: 3px;
  background: linear-gradient(90deg, #fff, transparent);
  animation: fadeInButton 1s forwards;
  animation-delay: 5.5s;

}

@keyframes animate-portal-up-beam {
  0% {
    transform: rotate(270deg) translateX(0);
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: rotate(270deg) translateX(-300px);
    opacity: 0;
  }
}

.portal-up-beam span:nth-child(1) {
  top: -450px; /*верхняя координата*/
  left: -98px; /*правая координата*/

  animation-delay: 0.5s; /*задержка анимации*/
  animation-duration: 2s; /*длительность анимации*/
}

.portal-up-beam span:nth-child(2) {
  top: -450px; /*верхняя координата*/
  left: -1px;
  animation-delay: 0s;
  animation-duration: 2s;
}

.portal-up-beam span:nth-child(3) {
  top: -450px; /*верхняя координата*/
  left: 94px;
  animation-delay: 1s;
  animation-duration: 2s;
}


.main-subtitle br {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 2s 1s ease forwards;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.main-btn > * {
  opacity: 0;
  animation: fadeInButton 2s forwards;
}

.main-btn > *:nth-child(1) {
  animation-delay: 5s;
}

.main-btn > *:nth-child(2) {
  animation-delay: 5.5s;
}

.main-btn > *:nth-child(3) {
  animation-delay: 5.5s;
}

@keyframes fadeInButton {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/*---------------------------------------------------------------------------------------------*/
/*                                  MEDIA MIN_WIDTH                                            */
/*---------------------------------------------------------------------------------------------*/

@media (min-width: 620px) {
  .main {
  }

  .portal-up-line svg{
    top:-386px;
  }

  @keyframes animate-portal-up-beam {
    0% {
      transform: rotate(270deg) translateX(0);
    }
    70% {
      opacity: 1;
    }
    100% {
      transform: rotate(270deg) translateX(-300px);
      opacity: 0;
    }
  }

  .main-container {
    margin-top: 108px;
    margin-bottom: 389px;
  }

  .main-btn {
    flex-direction: row;
  }

  .main-subtitle {
    max-width: 600px;
    font-size: 20px;
  }

}

@media (min-width: 1000px) {

  .portal-up-line svg{
    top:-476px;
  }

  @keyframes animate-portal-up-beam {
    0% {
      transform: rotate(270deg) translateX(0);
    }
    70% {
      opacity: 1;
    }
    100% {
      transform: rotate(270deg) translateX(-400px);
      opacity: 0;
    }
  }
  .main {
    padding: 0;
  }

  .main-container {
    margin: 232px auto 273px auto;
  }

  .main-subtitle {
    max-width: 482px;
    margin-bottom: 30px;
    margin-top: 20px;
    font-size: 20px;
  }
}
