.advantages {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  color: #F1F1F1;
  position: relative;
  text-align: center;
  overflow: hidden;

  /*border: 1px seagreen solid;*/
}

.advantages-text-container {
  /*border: 1px red solid;*/
}

.big-planet {
  margin-left: auto;
  position: absolute;
  /*top: 10%;*/
  /*left: 20%;*/
  width: 480px;
  height: auto;
}

.big-planet svg {
  width: 100%;
  height: 100%;
}

.small-planet {
  margin-right: auto;
  position: absolute;
  /*bottom: 15%;*/
  /*left: -5%;*/
  width: 224px;
  height: auto;
}

.small-planet svg {
  width: 100%;
  height: 100%;
}


/*@keyframes rotate-big-planet {*/
/*  0%, 100% {*/
/*    transform: translate(30vw, 20vw) rotate(0deg);*/
/*  }*/
/*  25% {*/
/*    transform: translate(0vw, -10vw);*/
/*  }*/
/*  50% {*/
/*    transform: translate(-50vw, 20vw) rotate(-20deg);*/
/*  }*/
/*  75% {*/
/*    transform: translate(-20vw, 50vw);*/
/*  }*/
/*}*/

/*@keyframes rotate-small-planet {*/
/*  0%,*/
/*  100% {*/
/*    transform: translate(0vw, -20vw) rotate(0deg);*/
/*  }*/
/*  25% {*/
/*    transform: translate(30vw, 15vw);*/
/*  }*/
/*  50% {*/
/*    transform: translate(70vw, -20vw) rotate(-20deg);*/
/*  }*/
/*  75% {*/
/*    transform: translate(40vw, -60vw);*/
/*  }*/
/*}*/

/*.big-planet {*/
/*  animation: rotate-big-planet 20s linear infinite;*/
/*}*/

/*.small-planet {*/
/*  animation: rotate-small-planet 20s linear infinite;*/
/*}*/

/*---------------------------------------------------------------------------------------------*/
/*                                  MEDIA MIN_WIDTH                                            */
/*---------------------------------------------------------------------------------------------*/


@media (min-width: 620px) {
  /*.advantages {*/
  /*  margin-right: auto;*/
  /*  margin-left: auto;*/
  /*  width: auto;*/
  /*}*/
  .big-planet {
    /*top: 5%;*/
    /*right: -10%;*/
    width: 520px;
  }

  .small-planet {
    /*bottom: 5%;*/
    /*left: 0;*/
  }

  /*  @keyframes rotate-big-planet {*/
  /*    0%,*/
  /*    100% {*/
  /*      transform: translate(10vh, 25vh) rotate(0deg);*/
  /*    }*/
  /*    25% {*/
  /*      transform: translate(-40vh, -6vh);*/
  /*    }*/
  /*    50% {*/
  /*      transform: translate(-80vh, 25vh) rotate(-20deg);*/
  /*    }*/
  /*    75% {*/
  /*      transform: translate(-30vh, 50vh);*/
  /*    }*/
  /*  }*/
  /*  @keyframes rotate-small-planet {*/
  /*    0%,*/
  /*    100% {*/
  /*      transform: translate(0vh, -25vh) rotate(0deg);*/
  /*    }*/
  /*    25% {*/
  /*      transform: translate(50vh, 0vh);*/
  /*    }*/
  /*    50% {*/
  /*      transform: translate(90vh, -25vh) rotate(-20deg);*/
  /*    }*/
  /*    75% {*/
  /*      transform: translate(50vh, -60vh);*/
  /*    }*/
  /*  }*/
  /*}*/
}

@media (min-width: 1000px) {

  .big-planet {
    /*right: 0;*/
    /*top: 0;*/
    width: 630px;
  }

  .small-planet {
    left: 5%;
    width: 300px;
    /*bottom: 0;*/
  }

  /*@keyframes rotate-big-planet {*/
  /*  0%,*/
  /*  100% {*/
  /*    transform: translate(10vh, 25vh) rotate(0deg);*/
  /*  }*/
  /*  25% {*/
  /*    transform: translate(-20vh, 0vh);*/
  /*  }*/
  /*  50% {*/
  /*    transform: translate(-50vh, 25vh) rotate(-20deg);*/
  /*  }*/
  /*  75% {*/
  /*    transform: translate(-30vh, 50vh);*/
  /*  }*/
  /*}*/
  /*@keyframes rotate-small-planet {*/
  /*  0%,*/
  /*  100% {*/
  /*    transform: translate(-5vh, -30vh) rotate(0deg);*/
  /*  }*/
  /*  12% {*/
  /*    transform: translate(5vh, -10vh);*/
  /*  }*/
  /*  25% {*/
  /*    transform: translate(30vh, 0);*/
  /*  }*/
  /*  37% {*/
  /*    transform: translate(60vh, -10vh);*/
  /*  }*/
  /*  50% {*/
  /*    transform: translate(80vh, -30vh) rotate(-20deg);*/
  /*  }*/
  /*  62% {*/
  /*    transform: translate(70vh, -50vh);*/
  /*  }*/
  /*  75% {*/
  /*    transform: translate(40vh, -65vh);*/
  /*  }*/
  /*  87% {*/
  /*    transform: translate(5vh, -60vh);*/
  /*  }*/
  /*}*/
}

@media (min-width: 1200px) {
  .advantages {
    width: 1200px;
  }

  .big-planet {
    /*right: 0;*/
    width: 672px;
  }

  .small-planet {
    /*left: 0;*/
    width: 350px;
  }

  .beam .beam-advantages {
    position: absolute;
    animation: animate-beam-advantages 3s linear infinite;
  }

  .beam .beam-advantages:before {
    content: '';
    position: absolute;
    transform: translateY(0%);
    width: 200px;
    height: 3px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.8), transparent);
  }

  .beam .beam-advantages:nth-child(1) {
    top: 55%;
    left: 10%;
    animation-delay: 0s; /*задержка анимации*/
    animation-duration: 2s; /*длительность анимации*/
  }

  .beam .beam-advantages:nth-child(2) {
    top: 55%;
    right: -10%;
    animation-delay: 0s;
    animation-duration: 2s;
  }
}



@keyframes animate-beam-advantages {
  0% {
    transform: rotate(0deg) translateX(0%);
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: rotate(0deg) translateX(-500px);
    opacity: 0;
  }


  /*@keyframes rotate-big-planet {*/
  /*  0%,*/
  /*  100% {*/
  /*    transform: translate(0vh, 25vh) rotate(0deg);*/
  /*  }*/
  /*  12% {*/
  /*    transform: translate(-5vh, 10vh);*/
  /*  }*/
  /*  25% {*/
  /*    transform: translate(-20vh, 0vh);*/
  /*  }*/
  /*  37% {*/
  /*    transform: translate(-50vh, 5vh);*/
  /*  }*/
  /*  50% {*/
  /*    transform: translate(-75vh, 25vh) rotate(-20deg);*/
  /*  }*/
  /*  62% {*/
  /*    transform: translate(-70vh, 35vh);*/
  /*  }*/
  /*  75% {*/
  /*    transform: translate(-30vh, 45vh);*/
  /*  }*/
  /*  87% {*/
  /*    transform: translate(-5vh, 40vh);*/
  /*  }*/
  /*}*/
  /*@keyframes rotate-small-planet {*/
  /*  0%,*/
  /*  100% {*/
  /*    transform: translate(0vh, -30vh) rotate(0deg);*/
  /*  }*/
  /*  12% {*/
  /*    transform: translate(20vh, -10vh);*/
  /*  }*/
  /*  25% {*/
  /*    transform: translate(50vh, 0);*/
  /*  }*/
  /*  37% {*/
  /*    transform: translate(90vh, -10vh);*/
  /*  }*/
  /*  50% {*/
  /*    transform: translate(110vh, -30vh) rotate(-20deg);*/
  /*  }*/
  /*  62% {*/
  /*    transform: translate(90vh, -50vh);*/
  /*  }*/
  /*  75% {*/
  /*    transform: translate(60vh, -60vh);*/
  /*  }*/
  /*  87% {*/
  /*    transform: translate(10vh, -45vh);*/
  /*  }*/
  /*}*/
}

@media (min-width: 1460px) {
  .advantages {
    width: 1327px;
  }

  .big-planet {
    /*right: 0;*/
  }

  .beam .beam-advantages:before {
    width: 400px;
  }
}
