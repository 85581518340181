.for-users {
  color: #F1F1F1;
  position: relative;

  margin: 150px 15px 50px 15px;
}

.for-users__title {
  font-size: 60px;
  position: sticky;

  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  z-index: 1;
}

.card-wrapper {

  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  gap: 100px;
}

.card {
  border: 1px solid #2B2B2B;
  text-align: start;
  border-radius: 35px;
  padding: 25px 35px;

  box-sizing: border-box;

  background: linear-gradient(260.84deg, #1C1C23 63.69%, #000000 116.72%);
}

.card-bridge {
  border: 1px solid #36B0F133;

  text-align: start;
  border-radius: 20px;
  box-sizing: border-box;

  margin-right: auto;
  margin-left: auto;
  padding: 40px 30px;

  background: linear-gradient(356.2deg, #0E2939 34.52%, #0B0B0D 88.11%);
}

.card-title {
  font-weight: 600;
  font-size: 18px;
}

.card-title p {
  font-size: 12px;
}

.card-bridge__btn {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  text-align: center;
}

/*---------------------------------------------------------------------------------------------*/
/*                                  MEDIA MIN_WIDTH                                            */
/*---------------------------------------------------------------------------------------------*/
@media (min-width: 620px) {
  .for-users {
    margin: 200px 35px 50px 35px;
  }

  .card-wrapper {
    gap: 100px;
  }

  .card {
    width: 480px;
  }

  .card-bridge {
    width: 500px;
    padding: 50px 30px;
  }

  .card-bridge__btn {
    flex-direction: row;
  }

  .card-wrapper .card:nth-child(1) {
    margin-left: 50px;
  }

  .card-wrapper .card:nth-child(2) {
    margin-left: auto;
  }

  .card-wrapper .card:nth-child(3) {
    margin-right: auto;
  }

}

@media (min-width: 1000px) {
  .for-users {
    margin: 200px auto 50px auto;

  }

  .for-users__title {
    font-size: 80px;
  }

  .card-wrapper {
    max-width: 1070px;
    padding-left: 50px;
    padding-right: 50px;
    margin: 0 auto;
    gap: 100px;
  }

  .card {
    width: 410px;
    gap: 100px;
  }

  .card-wrapper .card:nth-child(1) {
    margin-left: 70px;
  }
}

@media (min-width: 1440px) {
  .for-users {
    width: 1170px;
  }

  .for-users__title {
    font-size: 96px;
    font-weight: 500;
  }

  .card {
    padding: 34px 42px;
  }

  .card-bridge {
    margin-top: 40px;
    width: 520px;
    padding: 64px 30px;
  }

  .card-title {
    font-size: 22px;
    margin: 0 0 10px 0;
  }

  .card-title p {
    font-size: 16px;
  }

  .card-bridge__btn {
    font-size: 16px;
  }

  .card-wrapper .card:nth-child(1) {
    margin-left: 110px;
  }
}
