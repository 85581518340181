
.roadmap-info{
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  padding: 0 15px;
}
.scroll{
  margin-top: 10px;
  position: relative;
}

.q2,.q3,.q4,.q2025{
  display: flex;
  gap: 20px;
  overflow-y:hidden ;
  padding-bottom: 50px;
}

.roadmap-title{
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.roadmap-title h5 {
  color: #F1F1F1;
  font-size: 28px;
  font-weight: 500;
  margin: 0 ;
}

.year {
  font-size: 28px;
  color: #47BCFC;
}

.points {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.points-item {
  color: #F1F1F1;
  background:
  linear-gradient(260.84deg, #1C1C23 63.69%, #000000 116.72%);

  display: flex;
  gap:15px;
  margin-right: auto;

  font-size: 14px;
  border-radius: 700px;
  padding: 15px 20px;
  align-items: center;
}
.beam-scroll{
  z-index: 100;
  position: sticky;

}
.beam-scroll span{
  position: absolute;

  animation: animate-roadmap-beam 3s linear infinite;
}
.beam-scroll span:before{
  content: '';
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  width: 150px;
  height: 3px;
  background: linear-gradient(90deg,#fff,transparent);
}


@keyframes animate-roadmap-beam{
  0%
  {
    transform: rotate(270deg) translateX(0);
  }
  70%
  {
    opacity: 1;
  }
  100%
  {
    transform: rotate(270deg) translateX(-700px);
    opacity: 0;
  }
}
.beam-scroll span:nth-child(1){
  top: 150px;/*верхняя координата*/
  left: 15px;/*правая координата*/

  animation-delay: 0s;/*задержка анимации*/
  animation-duration: 3s;/*длительность анимации*/
}
.beam-scroll span:nth-child(2){
  top: 150px;/*верхняя координата*/
  left: 15px;/*правая координата*/

  animation-delay: 1.7s;/*задержка анимации*/
  animation-duration: 3s;/*длительность анимации*/
}


/*---------------------------------------------------------------------------------------------*/
/*                                  MEDIA MIN_WIDTH                                            */
/*---------------------------------------------------------------------------------------------*/


@media (min-width: 620px) {
  .points{
    flex-direction: row;
    flex-wrap: wrap;
    width: 550px;
  }
  .points-item{
    margin-right: 0;
  }
  .roadmap-title{
    margin-bottom: 20px;
  }
}

@media (min-width: 1000px) {
  .roadmap{
    margin-left: auto;
    margin-right:auto ;
  }
  .q2,.q3,.q4,.q2025{
    width: 900px;
  }
  .points-wrapper{
    width: 900px;
  }
  .points{
    width: auto;
  }
  .roadmap-info{
    margin-left: auto;
    margin-right: auto;
    width: 950px;
  }

  .roadmap-title{
    margin-bottom: 30px;
  }
  .roadmap-title h5 {
    font-size: 35px;
    font-weight: 500;
  }

  .year {
    font-size: 35px;
  }
  .points-item{
    font-size: 18px;
  }


}

@media (min-width: 1200px) {

}

@media (min-width: 1440px) {
  .roadmap{
    margin-left: auto;
    margin-right:auto ;
  }
  .q2,.q3,.q4,.q2025{
    width: 900px;
  }
  .points-wrapper{
    width: 900px;
  }
  .points{
    width: auto;
  }
  .roadmap-info{
    margin-left: auto;
    margin-right: auto;
    width: 950px;
  }

  .roadmap-title{
    margin-bottom: 30px;
  }


}
