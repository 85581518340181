@font-face {
  font-family: 'Poppins Regular';
  src: url('../public/fonts/Poppins-Regular/Poppins-Regular.eot');
  src: url('../public/fonts/Poppins-Regular/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
  url('../public/fonts/Poppins-Regular/Poppins-Regular.woff') format('woff'),
  url('../public/fonts/Poppins-Regular/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Bold';
  src: url('../public/fonts/Poppins-Bold/Poppins-Bold.eot');
  src: url('../public/fonts/Poppins-Bold/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
  url('../public/fonts/Poppins-Bold/Poppins-Bold.woff') format('woff'),
  url('../public/fonts/Poppins-Bold/Poppins-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Medium';
  src: url('../public/fonts/Poppins-Medium/Poppins-Medium.eot');
  src: url('../public/fonts/Poppins-Medium/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
  url('../public/fonts/Poppins-Medium/Poppins-Medium.woff') format('woff'),
  url('../public/fonts/Poppins-Medium/Poppins-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins Regular";
  margin: 0;
  box-sizing: border-box;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
