.text-container {
  font-family: "Poppins Regular";
  animation: scale 3s ease-in-out;
  display: inline-block;
  text-align: center;
  font-size: 60px;
  max-width: 320px;
  position: relative;
  z-index: 500;

}

.word-span {
  transition: color 2s;
}

.word-span.highlight {
  color: white;
  transition: color 1s;
}

/*---------------------------------------------------------------------------------------------*/
/*                                  MEDIA MIN_WIDTH                                            */
/*---------------------------------------------------------------------------------------------*/


@media (min-width: 620px) {
  .text-container {
    max-width: 400px;
    font-size: 75px;
  }
}

@media (min-width: 1000px) {
  .text-container {
    max-width: 800px;
  }
}

@media (min-width: 1220px) {
  .text-container {
    max-width: 1113px;
  }
}

