.menu {
  position: absolute;
  top: 110%;
  right: 1%;

}

.menu.open {
  display: block;
}

.menu.closed {
  display: none;
  position: relative
}

.menu-container {
  border-radius: 20px;
  background: linear-gradient(239.15deg, #1C1C23 26.27%, #000000 73.3%);
  width: 330px;
  height: 478px;

  display: flex;
  flex-direction: column;
  padding-top: 15px;

}

.menu-container > *:nth-child(1) {
  align-self: end;
  margin-right: 15px;
}
.menu-container > *:nth-child(2) {
  margin-top: 0;
}

.menu-container a {
  text-align: center;
  display: flex;
  gap: 20px;
  flex-direction: column;
  font-size: 22px;
  text-decoration: none;
  color: #F1F1F1;
  margin-top: 20px;
  margin-left: 45px;
  margin-right: 45px;
}


.line:after {
  content: " ";

  border-bottom: 1px solid rgba(115, 115, 118, 0);
  border-image: linear-gradient(to right, rgba(115, 115, 118, 0), #737376, rgba(115, 115, 118, 0)) 1;
}

.line:target {
  padding-top: 20%;
  margin-top: 20%;
}

.menu-container a:hover {
  color: #47BCFC;
}

/*---------------------------------------------------------------------------------------------*/
/*                                  MEDIA MIN_WIDTH                                            */
/*---------------------------------------------------------------------------------------------*/

@media (min-width: 620px) {
}

@media (min-width: 1000px) {
  .menu {
    display: block;
    position: relative;
    z-index: 1000;
    order: 0;

  }

  .menu.open {
    display: block;
    z-index: 1000;

  }

  .menu.closed {
    display: block;
  }

  .menu-container {
    flex-direction: row;
    justify-content: center;
    align-items: center;

    z-index: 1000;


    height: 64px;
    width: 600px;

    gap: 20px;
    padding: 0 20px;

    background: linear-gradient(259.78deg, #1C1C23 14.56%, #000000 65.11%);

    border: 1px solid #2B2B2B;
    border-radius: 83px;
  }

  .menu-container > *:nth-child(1) {
    display: none;
  }

  .menu-container a {
    font-size: 16px;
    margin: 0;
  }

  .line:after {
    display: none;
  }
}

@media (min-width: 1200px) {
  .menu-container {
    width: 732px;
    justify-content: space-between;
    padding: 0 40px;
  }
}
