.form-page{
  padding: 27px 15px 35px 15px;
  background-image: url("../../../public/backgroind image main.png");
  background-repeat: no-repeat;
  background-size: auto 50%; /* 80% высоты экрана */
  background-position: top right;
  animation: fadeIn 2s 1s ease-in-out forwards;
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
