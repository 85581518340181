.new-form-page {
  padding: 27px 15px 35px 15px;
  background-image: url("../../../public/backgroind image main.png");
  background-repeat: no-repeat;
  background-size: auto 50%; /* 80% высоты экрана */
  background-position: top right;
  animation: fadeIn 2s 1s ease-in-out forwards;
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.form-successful-container {
  color: #FFFFFF;
  margin: 106px 0;

}

.form-successful-title {
  text-align: center;
  margin-bottom: 30px;
}

.form-successful-title-lite {
  font-size: 42px;
  opacity: 50%;
  font-weight: 300;
}

.form-successful-title-bold {
  font-size: 42px;
  font-weight: 600;
  opacity: 100%;
  letter-spacing: 1px;
}

.form-successful-link {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-successful-link a {
  background: linear-gradient(259.78deg, #1C1C23 14.56%, #000000 65.11%);
  color: #FFFFFF;
  text-decoration: none;
  display: flex;
  gap: 10px;
  border-radius: 71px;
  justify-content: center;
  align-items: center;
  height: 55px;
}

.form-successful-link a svg {
  width: 17px;
}

.form-successful-link a svg path {
  fill: #47BCFC;
}

/* Иконки в инпутах */
Input[name="projectTwitterLink"] {
  background-image: url("../../ui/icons/twitter.svg");
  background-size: 17px 17px;
  background-position: 5%;
  background-repeat: no-repeat;
  padding-left: 50px;
}

Input[name="projectTelegramLink"] {
  background-image: url("../../ui/icons/telegram.svg");
  background-size: 17px 17px;
  background-position: 5%;
  background-repeat: no-repeat;
  padding-left: 50px;
}

Input[name="projectWebsite"] {
  background-image: url("../../ui/icons/web.svg");
  background-size: 17px 17px;
  background-position: 5%;
  background-repeat: no-repeat;
  padding-left: 50px;
}

/* Конец иконок в инпутах */



/*---------------------------------------------------------------------------------------------*/
/*                                  MEDIA MIN_WIDTH                                            */
/*---------------------------------------------------------------------------------------------*/

@media (min-width: 620px) {
  .new-form-page {
    padding: 0;
  }
  .submit-container{
    width: 600px;
  }
  .form-successful-container {
    margin: 150px 0;
  }

  .form-successful-title-lite {
    font-size: 60px;
  }

  .form-successful-title-bold {
    font-size: 60px;
  }

  .form-successful-link {
    flex-direction: row;
    gap: 25px;
    justify-content: center;
  }

  .form-successful-link a {
    height: 55px;
    width: 203px;
  }

  .form-successful-link a svg {
    width: 17px;
  }

  .form-successful-link a svg path {
    fill: #47BCFC;
  }

}

@media (min-width: 1000px) {
  .submit-container{
    width: 960px;
    margin-left: auto;
    margin-right: auto;
  }

}
