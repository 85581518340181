.product {
  max-width: 1290px;
  color: #FFFFFF;

  margin: 0 15px;

  display: flex;
  flex-direction: column;

  align-items: center;

  position: relative;
  overflow-x: hidden;

}

.portal-down {
  /*height: 574px;*/
  display: flex;
  justify-content: center;
}
.portal-down-line{
  display: flex;
  justify-content: center;
  position: relative;
}
.portal-down-line svg{
  position: absolute;
  top:-35px;
}
.for-developers {
  position: relative;
  z-index: 1000;
  opacity: 40%;
  margin-top: 495px; /* место для портала*/
  text-align: start;
}

.for-developers h4 {
  font-size: 28px;
  font-weight: 500;
  margin: 0;
}

.for-developers p {
  margin: 10px 0 20px 0;
}

.content {
  box-sizing: border-box;
  padding: 25px 35px;

  border: 1px solid #2B2B2B;
  width: 330px;
  border-radius: 35px;
  background: linear-gradient(260.84deg, #1C1C23 63.69%, #000000 116.72%);
}

.content-title {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
}

.content p {
  display: inline-block;
  width: 270px;
  font-size: 14px;
}

.partners-wrapper {
  display: flex;
}

.for-partners {
  z-index: 1000;
  display: flex;
  text-align: start;
  flex-direction: column;
  margin-top: 50px;
  gap: 20px;

  opacity: 40%;
}

.for-partners h4 {
  font-size: 28px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
}

.for-developers.visible,
.for-partners.visible {
  opacity: 1 !important;
}


.portal-down-beam {
  z-index: 100;
  width: 130px;
  position: absolute;
}

.portal-down-beam span {
  position: absolute;
  animation: animate-portal-down-beam 3s linear infinite;
}

.portal-down-beam span:before {
  content: '';
  position: absolute;
  transform: translateY(-50%);
  width: 300px;
  height: 3px;
  background: linear-gradient(90deg, #fff, transparent);
}


@keyframes animate-portal-down-beam {
  0% {
    transform: rotate(270deg) translateX(0);
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: rotate(270deg) translateX(-300px);
    opacity: 0;
  }
}

.portal-down-beam span:nth-child(1) {
  top: 450px;
  /*верхняя координата*/
  left: -30px; /*правая координата*/

  animation-delay: 0.5s; /*задержка анимации*/
  animation-duration: 2s; /*длительность анимации*/
}

.portal-down-beam span:nth-child(2) {
  top: 450px;
  left: 67px;
  animation-delay: 0s;
  animation-duration: 2s;
}

.portal-down-beam span:nth-child(3) {
  top: 450px;
  left: 160px;
  animation-delay: 1s;
  animation-duration: 2s;
}


/*---------------------------------------------------------------------------------------------*/
/*                                  MEDIA MIN_WIDTH                                            */
/*---------------------------------------------------------------------------------------------*/

@media (min-width: 620px) {
  .product {
    margin: 0 35px;
  }

  .for-developers {
    margin-top: 623px;
    margin-right: auto;
    width: 480px;
  }

  .content {
    width: 480px;
  }

  .content p {
    width: 420px;
  }

  .partners-wrapper {
    justify-content: end;
    margin-left: auto;
  }

  .for-partners {
    margin-top: 106px;
  }

  .for-partners h4 {
    text-align: end;
  }
  @keyframes animate-portal-down-beam {
    0% {
      transform: rotate(270deg) translateX(0);
    }
    70% {
      opacity: 1;
    }
    100% {
      transform: rotate(270deg) translateX(-300px);
      opacity: 0;
    }
  }

}

@media (min-width: 1000px) {
  .product {
    margin: 0 50px;
  }

  .for-developers {
    margin-top: 450px;
    display: flex;
    text-align: start;
    flex-direction: column;
  }

  .for-developers h4 {
    font-size: 32px;
  }

  .for-developers p {
    font-size: 16.6px;
  }

  .content {
    width: 410px;
    padding: 34px 42px;
  }

  .content-title {
    font-size: 22px;
  }

  .content p {
    font-size: 16px;
    width: 326px;
  }

  .for-partners {
    width: 410px;
    margin-top: 94px;
  }

  .for-partners h4 {
    font-size: 32px;
    font-weight: 500;
    margin: 0;
  }
  @keyframes animate-portal-down-beam {
    0% {
      transform: rotate(270deg) translateX(0);
    }
    70% {
      opacity: 1;
    }
    100% {
      transform: rotate(270deg) translateX(-500px);
      opacity: 0;
    }
  }
}

@media (min-width: 1440px) {
  .product {
    width: 1290px;
    margin: 0 auto;
  }
}




