.burger-menu{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 0;
    outline: none;
    border: none;
    align-self:center ;

    @media (min-width: 620px) {
        margin-top: 0;
    }
    @media (min-width: 1000px) {
        display: none;
    }
}
.burger-menu:active{
    box-shadow: 0 0 16.7px 0 #47BCFC94;
}
/*---------------------------------------------------------------------------------------------*/
/*                                  MEDIA MIN_WIDTH                                            */
/*---------------------------------------------------------------------------------------------*/

@media (min-width: 620px) {
    .burger-menu{
        margin-top: 0;
    }
}

@media (min-width: 1000px) {
    .burger-menu{
        display: none;
    }
}
