.about-project{
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.about-project__container{
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.input-wrapper{
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.about-project__title{
  font-size: 26px;
  text-align: start;
}
.about-project:after{
    content: " ";

    border-bottom: 1px solid rgba(115, 115, 118, 0);
    border-image: linear-gradient(to right, rgba(115, 115, 118, 0), #737376, rgba(115, 115, 118, 0)) 1;
}

/*---------------------------------------------------------------------------------------------*/
/*                                  MEDIA MIN_WIDTH                                            */
/*---------------------------------------------------------------------------------------------*/

@media (min-width: 620px) {
  .about-project{
    gap: 50px;
  }
  .about-project__container{
    width: 480px;
    margin-right: auto;
    margin-left: 35px;
  }
  .about-project__title{
    font-size: 32px;
  }
}
@media (min-width: 1000px) {
  .input-wrapper{
    flex-direction: row;
  }
  .about-project__container{
    width: 630px;
  }
}
