.logo {
  margin-right: 100px;
  cursor: pointer;
}

@media (min-width: 588px) {
  .logo {
    margin-right: 0;
  }
}

@media (min-width: 1000px) {
  .logo {
    margin-right: 0;
  }
}