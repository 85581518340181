.blue-btn{
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    gap:10px;

    outline: none;
    background: linear-gradient(180deg, #47BCFC 0%, #078FD1 100%);
    border: none;
    border-radius: 71px;
    padding: 20px 35px 20px 35px;

    color: #F1F1F1;
    font-weight: 600;
    font-family: "Poppins Regular";

    height: 55px;
    transition: background-color 0.5s, box-shadow 0.5s;
    @media (min-width: 720px) {

    }
    @media (min-width: 1440px) {
        padding: 20px 75px 20px 75px;

    }
}

.blue-btn p{
    margin: 0;
    font-size: 16px;
    align-self: center;

}

.blue-btn:hover
{
    box-shadow: 0 0 16.7px 0 #47BCFC94;

}
.blue-btn:active
{
    box-shadow: 0 0 40px 0 #47BCFC94;
}

