.join {
  margin: 0 auto 53px auto;
  width: 330px;
}

.join-title h2 {
  z-index: 1000;

  font-size: 48px;

  color: #F1F1F1;
  margin-bottom: 20px;
  font-weight: 700;
  line-height: 1.2;
  opacity: 0;

  text-align: center;
}

.join h2 span {
  animation: fadeInLetter 1s ease-in-out both;
}

@keyframes fadeInLetter {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.3;
  }
  20%, 70% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.join-btn {
  z-index: 1000;
  position: relative;
  display: flex;
  justify-content: center;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 1s 2s, transform 1s 2s;
}

.join-btn.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.join-title {
  display: flex;
  justify-content: center;
}

.join-title h2:nth-child(2) {
  margin-right: 40px;
}

.beam-footer {
  width: 130px;
  height: 100px;
  position: absolute;
  left: 50%;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
}

.beam-footer span {
  position: absolute;

  animation: animate-beam-footer 3s linear infinite;
}

.beam-footer span:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 200px;
  height: 3px;
  background: linear-gradient(90deg, #fff, transparent);
}


@keyframes animate-beam-footer {
  0% {
    transform: rotate(270deg) translateX(0);
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: rotate(270deg) translateX(-400px);
    opacity: 0;
  }
}

.beam-footer span:nth-child(1) {
  top: -500px; /*верхняя координата*/
  left: 97px;

  animation-delay: 0s; /*задержка анимации*/
  animation-duration: 2s; /*длительность анимации*/
}

.beam-footer span:nth-child(2) {
  top: -500px;
  left: 0;

  animation-delay: 1s;
  animation-duration: 2s;
}

.beam-footer span:nth-child(3) {
  top: -500px;
  left: -97px;

  animation-delay: 0.5s;
  animation-duration: 2s;
}

.footer-line {
  opacity: 0;
  display: flex;
  justify-content: center;
  position: relative;
  animation: fadeIn 2s 2s ease-in-out forwards;

}
.footer-line svg{
  position: absolute;
  bottom: -500px;
}

/*---------------------------------------------------------------------------------------------*/
/*                                  MEDIA MIN_WIDTH                                            */
/*---------------------------------------------------------------------------------------------*/
@media (min-width: 620px) {
  .join {
    width: 510px;
    margin: 0 auto 79px auto;
  }

  .join-title h2 {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    font-size: 80px;
  }

  .join-title {
    margin-left: auto;
    margin-right: auto;
  }
  .beam-footer span:nth-child(1) {
    top: -750px; /*верхняя координата*/
  }

  .beam-footer span:nth-child(2) {
    top: -750px; /*верхняя координата*/
  }

  .beam-footer span:nth-child(3) {
    top: -750px; /*верхняя координата*/
  }
  @keyframes animate-beam-footer {
    0% {
      transform: rotate(270deg) translateX(0);
    }
    70% {
      opacity: 1;
    }
    100% {
      transform: rotate(270deg) translateX(-400px);
      opacity: 0;
    }
  }


}

@media (min-width: 1000px) {


  .join {
    width: 630px;
    margin-left: auto;
    margin-right: auto;
  }

  .join-title h2 {
    font-size: 96px;
  }

}

@media (min-width: 1440px) {
  .join {
    font-size: 96px;
    margin-bottom: 20px;
    width: 750px;
  }

  .join-title h2 {
    font-size: 96px;
    margin-bottom: 20px;
  }

  .join-title {
    font-size: 96px;
  }

  .beam-footer span:nth-child(1) {
    top: -1300px; /*верхняя координата*/
  }

  .beam-footer span:nth-child(2) {
    top: -1300px;
  }

  .beam-footer span:nth-child(3) {
    top: -1300px;
  }


  @keyframes animate-beam-footer {
    0% {
      transform: rotate(270deg) translateX(0);
    }
    70% {
      opacity: 1;
    }
    100% {
      transform: rotate(270deg) translateX(-400px);
      opacity: 0;
    }
  }


}
