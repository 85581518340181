.right {
  display: flex;
  flex-direction: column;

  gap: 20px;
}

.ca_coming-soon {
  box-sizing: border-box;
  display: flex;
  padding: 10px 10px 10px 20px;
  background: #0A0A0A;
  border-radius: 50px;
  justify-content: space-between;

  height: 60px;
}

.ca_text {
  display: flex;
  gap: 5px;
  align-self: center;

}

.coming-soon {
  font-weight: 400;
  font-size: 15px
}

.coming-soon .ca {
  font-weight: 600;
  font-size: 18px;
  vertical-align: center;
}

.copy {
  background: #161616;
  display: flex;
  justify-content: center;
  border-radius: 23px;

}

.copy svg {
  width: 16px;
  height: 16px;
  margin: 12px;
}

.right-social {
  height: 50px;
  display: flex;
  gap: 30px;
}

.right-social svg {
  height: 100%;
}


/*---------------------------------------------------------------------------------------------*/
/*                                  MEDIA MIN_WIDTH                                            */
/*---------------------------------------------------------------------------------------------*/
@media (min-width: 620px) {
  .right {
    align-items: end;
  }
}

@media (min-width: 1000px) {

  .ca_coming-soon {
    width: 300px;
  }
}

@media (min-width: 1200px) {
}

@media (min-width: 1440px) {
}