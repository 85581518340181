.footer {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;


  color: #F1F1F1;
  box-sizing: border-box;
  border: 1px solid #2B2B2B;
  border-radius: 30px;

  margin: 132px auto 40px auto;
  padding: 20px 35px;

  opacity: 100%;
  background: linear-gradient(259.78deg, #1C1C23 14.56%, #000000 65.11%);
  gap: 40px;
  z-index: 1000;
}

.link-container {
  display: flex;
  justify-content: space-between;
}




/*.line-footer span{*/

/*  animation: animate-beam-footer 3s linear infinite;*/
/*}*/
/*.line-footer span:after{*/
/*  content: '';*/
/*  position: absolute;*/
/*  rotate: 90deg;*/
/*  width: 2000px;*/
/*  height: 3px;*/
/*  background: linear-gradient(90deg, #ea1111,transparent);*/
/*}*/

/*.line-footer span:nth-child(1){*/
/*  top: -1050px;!*верхняя координата*!*/
/*  left: 60vw;!*правая координата*!*/
/*  animation-delay: 0s;!*задержка анимации*!*/
/*  animation-duration: 3s;!*длительность анимации*!*/
/*}*/
/*.line-footer span:nth-child(2){*/
/*  top: -900px;*/
/*  left: 50vw;*/
/*  animation-delay: 1s;*/
/*  animation-duration: 3s;*/
/*}*/
/*.line-footer span:nth-child(3){*/
/*  top: -850px;*/
/*  left: 40vw;*/
/*  animation-delay: 0.4s;*/
/*  animation-duration: 3s;*/
/*}*/


/*---------------------------------------------------------------------------------------------*/
/*                                  MEDIA MIN_WIDTH                                            */
/*---------------------------------------------------------------------------------------------*/

@media (min-width: 620px) {
  .footer {
    flex-direction: row;
  }

  .link-container {
    gap: 30px;
  }

  .footer-line svg{
    position: absolute;
    bottom: -420px;
  }
}

@media (min-width: 1000px) {
  .footer {
    max-width: 1290px;
  }

  .link-container {
    gap: 70px;
  }
}

@media (min-width: 1440px) {
  .footer {
    margin: 132px auto 40px auto;
    padding: 40px;
    width: 1290px;
  }
}
