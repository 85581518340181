.textarea {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: start;
}

textarea {
  box-sizing: border-box;

  font-family: "Poppins Regular";
  font-size: 12px;
  border: 1px solid #2B2B2B;
  height: 140px;
  padding: 15px 25px;
  color: #808080;
  outline: none;

  background: linear-gradient(259.78deg, #1C1C23 14.56%, #000000 65.11%);
  border-radius: 20px;
}

textarea:focus {
  border-color: #CFCFCF;
}

.textarea-label.error {
  color: var(--error-color);
}

.textarea-error-message {
  color: var(--error-color);
  font-size: var(--error-hint-size, 8pt);
}

/*---------------------------------------------------------------------------------------------*/
/*                                  MEDIA MIN_WIDTH                                            */
/*---------------------------------------------------------------------------------------------*/

@media (min-width: 620px) {
  textarea {
    width: 380px;
  }
}

@media (min-width: 1000px) {
  textarea {
    width: 630px;
  }

}
