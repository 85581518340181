.portal-up {
  display: flex;
  justify-content: center;
  margin-top: 300px;
}


.mission {
  margin: 0 auto 0 auto;
}

.mission-text {
  font-size: 24px;
  color: #1d1d1d;
  opacity: 1;
  text-align: center;
}


.mission-span.isVisible {
  animation: clip-pathing 3s ease-in-out both;
}

@keyframes clip-pathing {
  from {
    clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
    opacity: 0;
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    opacity: 1;
    color: white;
  }
}

/*---------------------------------------------------------------------------------------------*/
/*                                  MEDIA MIN_WIDTH                                            */
/*---------------------------------------------------------------------------------------------*/


@media (min-width: 620px) {
  .portal-up {

  }

  .mission {
    margin: 0 auto 0 auto;
    width: 550px;
  }

  .mission-text {
    font-size: 40px;
  }
}

@media (min-width: 1000px) {
  .portal-up {
  }

  .mission {
    margin: 0 auto 0 auto;
    width: 880px;
  }
}

