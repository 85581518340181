.white-btn {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    outline: none;
    background: #1D1D1D;
    border: none;
    border-radius: 71px;
    padding: 20px 35px 20px 35px;

    color: #F1F1F1;
    font-weight: 600;
    font-family: "Poppins Regular";

    height: 55px;
    transition: background-color 0.5s, box-shadow 0.5s;
}

.white-btn p {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    align-self: center;
}

.white-btn:hover {
    background: #F1F1F1;
    color: #1D1D1D;
}

.explore-btn:active {
    box-shadow: 0 0 16.7px 0 #FFFFFF96;
}

