.App {
  text-align: center;
  box-sizing: border-box;
  width: 100%;
}

.app {
  --error-color: #f65f5f;
  --error-hint-size: 8pt;
}
