.token {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  margin-top: 92px;
  margin-bottom: 432px;
  overflow-x: hidden;
  z-index: 1000;
  height: auto;
}

.circle1, .circle2 {
  transform-origin: 50% 50%; /* Устанавливаем центр трансформации в центр круга */
}

.token-info {
}

.token-info svg {
  top: 0;
  left: 25%;
  position: relative;
  width: 100%;
}

.token-text {
  width: 330px;
  color: #F1F1F1;
  font-size: 16px;
  line-height: 24px;
  text-align: start;
  padding: 20px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 3s, transform 1s;
}

.token-text.animate {
  opacity: 1;
  transform: translateY(0);
}

.token-text br {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 3s, transform 1s;
}

.token-text.animate br {
  opacity: 1;
  transform: translateY(0);
}

/*---------------------------------------------------------------------------------------------*/
/*                                  MEDIA MIN_WIDTH                                            */
/*---------------------------------------------------------------------------------------------*/
@media (min-width: 620px) {
  .token {
    margin-bottom: 469px;
    margin-top: 82px;
    gap: 70px;
  }

  .token-info {
    width: 480px;
    margin-left: auto;
    margin-right: auto;
  }

  .token-info svg {
    position: static;
    width: 100%;
  }

  .token-text {
    text-align: center;
    width: 480px;
    padding: 15px 0;
  }
}

@media (min-width: 1000px) {
  .token {
    margin: 227px auto 425px auto;
    padding: 0 70px;
    justify-content: space-between;
    max-width: 1294px;
  }
  .token-text {

    padding: 0 ;
  }
}

@media (min-width: 1200px) {
  .token {
    flex-direction: row;
    padding: 0;
  }
}
