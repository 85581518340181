
.check-box{
  display: flex;
  gap: 10px;
}
.hidden-checkbox {
  width: 17px;
  height: 17px;
  margin: 0;
  padding: 0;
  appearance: none;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  border: 2px solid #2B2B2B;
  position: relative;
  align-self: center;

}
.hidden-checkbox:focus {
  border-color: #2B2B2B;
}

.hidden-checkbox:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background: rgba(71, 188, 252, 0.76);
  border-color: rgba(71, 188, 252, 0.76);

}

/*---------------------------------------------------------------------------------------------*/
/*                                  MEDIA MIN_WIDTH                                            */
/*---------------------------------------------------------------------------------------------*/

@media (min-width: 620px) {

}

@media (min-width: 1000px) {
  .check-box__wrapper{align-self: center;}


}
